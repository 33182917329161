import phone from '../assets/phone.svg'
import React from 'react'
import email from '../assets/email.svg'
import address from '../assets/location.svg'
import { useRef, useState } from 'react'
import {HashLink as Lnk} from 'react-router-hash-link'
import { BrowserRouter, Link } from "react-router-dom";

import emailjs from '@emailjs/browser';
const Contact = () => {
    const [name, setName] = useState("");
    const [names, setNames] = useState([]);
    const formref=useRef();
    const [done,setDone]=useState(false)
    const handlesubmit=(e)=>{
        
        // e.preventDefault();
        emailjs.sendForm('service_6wnzg3n', 'template_nk9daxj', formref.current, 'QjkT47YdqNWiE94-a')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      
      setDone(true)

    }
    return ( 
        <div className="contact" id='contact'>
            <div className="c-bg"></div>
            <div className="c-wrapper">
                <div className="c-left">
                    <h1 className="c-title">CONTACT US</h1>
                    <div className="c-info">
                        <div className="c-info-item">
                            <img src={phone} alt="" className="c-icon" /><a href="tel:+1 4802704469" type="phone">+1 4802704469</a>
                        </div>
                    </div>
                    <div className="c-info">
                        <div className="c-info-item">
                            <img src={email} alt="" className="c-icon" /><a href="mailto:contact@verismosolutions.com" type="email">contact@verismosolutions.com</a>
                        </div>
                    </div>
                    <div className="c-info">
                        <div className="c-info-item">
                            <img src={address} alt="" className="c-icon" /><a type="address">ARIZONA, USA</a>
                        </div>
                    </div>
                </div>
                <div className="c-right">
                    <p className="c-desc">
                        <b>Get in touch.</b>
                    </p>
                    <form ref={formref} onSubmit={handlesubmit}>
                        <input type='text' placeholder='Name' name="user_name" />
                        <input type='text' placeholder='Email' name="user_email"/>
                        <input type='text' placeholder='Subject' name="user_Subject"/>
                        <textarea name="user_text"   rows="5" placeholder='Message'></textarea>
                        <button className='c-button'>Submit</button>
                    </form>
                    {done&& alert('Submited')}
                </div>
            </div>
        </div>
     );
}
 
export default Contact;