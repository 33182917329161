import appdev from '../assets/appdev.png'
import ecom from '../assets/ecom.png'
import ml from '../assets/ml.png'
export const Services=[
    {
        id:1,
        img: appdev,
        name:"App Development",
        
    },
    {
        id:2,
        img: ecom,
        name:"E-Commerce",
        
    },
    {
        id:3,
        img: ml,
        name:"Machine Learning/AI",
        
    },
   
]