import React from "react";
import '../app.css'
import Lottie from 'react-lottie';
import animationData from '../assets/about.json'



const About = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <div className="about" id="about">
            <div className="ab-left">
                
                <Lottie className='lttie'
                    options={defaultOptions}
                    height={600}
                    width={600}
                />
            </div>
            <div className="ab-right">
            <h1>Who Are We?</h1>
                <p className="ab-para">
                    We are a service providing company based in United States. Our motive is to provide the best services at a fairly low price. We also provide tech support to ones in need. If there is any tech related query, just contact us. Our employee will try to get back to you as soon as possible.
                </p>
            </div>
        </div>
    );
}

export default About;