// import logo from './logo.svg';
// import './App.css';
import React from "react";
import Home from "./components/home";
import About from "./components/about";
import Contact from "./components/contact";
// import Navbar from "./components/navbar";
// import {BrowserRouter} from 'react-router-dom'

function App() {
  return (
    // <BrowserRouter>
    <div className="App">
      {/* <Navbar/> */}
    <Home/>
    <About/>
    <Contact/>
    </div>
    // </BrowserRouter>
  );
}

export default App;
