import React from "react";
import '../app.css'
import Navbar from "./navbar.js";
import Lottie from 'react-lottie';
import animationData from '../assets/animation2.json'
import { Services } from "./data";
import {  motion } from "framer-motion/dist/framer-motion";
import { BrowserRouter} from "react-router-dom";
import {HashLink as Lnk} from 'react-router-hash-link'

// import { motion, useAnimation } from "framer-motion";

// import { useInView } from "react-intersection-observer";
// import { useInView } from "react-intersection-observer";

// import { useEffect } from "react";
// import { useState } from "react";

const Home = () => {
    // const control = useAnimation()
// const [ref, inView] = useInView()
   
    
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <div className="home" id="#home">
            <div className="h1" id="h1">

                <Navbar />
                <div className="hm">
                    <div className="h-left">
                        <div className="heading">

                            <h1 >
                                NEED A WEBSITE FOR YOUR BUSSINESS?
                            </h1>
                            <h2>
                                WE ARE THE PERFECT SOLUTION
                            </h2>
                            <BrowserRouter>
                            <button className="btnservices"><Lnk to="#serv" className="btlnk" smooth>SERVICES</Lnk></button>
                            <button className="btnservicess"><Lnk to="#contact" className="btlnk" smooth>CONTACT US</Lnk></button>
                            </BrowserRouter>
                        </div>
                    </div>
                    <div className="h-right">
                        <div className="lottiee">

                            <Lottie
                                options={defaultOptions}
                                height={600}
                                width={600}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="h2">

            <div className="overlay" id="h2">
                <div className="oc1" id="serv">
                    {/* <img className="cbg" src="https://images.unsplash.com/photo-1519408469771-2586093c3f14?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTkxfHx3ZWIlMjBkZXNpZ258ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60" alt="" /> */}
                    <h2>Web Development</h2>
                    <p className="para">
                        Full Stack Web Development services available. We use modern frameworks and new technologies for the development purpose. From a simple static website to an advanced dynamic website, we make them all.
                    </p>
                </div>
                <div className="oc2">
                    {/* <img src="" alt="" className="cbg" /> */}
                    <h2>UI/UX Design</h2>
                    <p className="para">
                        UX/UI designing services available. Prototyping services are also available. Technologies like Figma and Adobe XD are used for the purpose. Experienced designers will be dealing with you.
                    </p>
                </div>
                <div className="oc3">
                    <h2>Website Servicing</h2>
                    <p className="para">
                        Already have a website and struggling with the servicing part or want to make some changes? WE GOT YOU COVERED. Website servicing and maintainance services available
                    </p>

                </div>
            </div>
            <div className="oservices" id="services">
                <h2>Other Services</h2>
                 <div className="ser">
                {Services.map((item)=>(
                    
                    
                    <motion.div 
                    
                    // initial={{opacity:0.9}}
                    // whileHover={{opacity:1}}
                    initial={{scale:1,opacity:1}}
                    whileHover={{scale:1.1}}
                    className="avatardiv">
                        
                        <motion.div 
                        className="avatar">
                        <motion.img
                            initial={{scale:0.9,opacity:1}}
                            whileHover={{scale:1}}
                            
                            src={item.img} alt="" />
                        </motion.div>
                        <h3>{item.name}</h3>
                        </motion.div>
                        
                        // </div>
                        
                        
                        ))}
</div>
              
                        </div>
            </div>

        </div>
    );
}

export default Home;