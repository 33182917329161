import React, { useRef, useState } from "react";
import '../app.css'
// import Lottie from 'react-lottie';
import { BrowserRouter, Link } from "react-router-dom";
// import Home from "./home";
// import Contact from "./contact";
import {HashLink as Lnk} from 'react-router-hash-link'
// import RightNav from "./rightnav";
// import styled from "styled-components";
// import Burger from "./burger";
import hamb from '../assets/hamb.png'
import {FaBars,FaTimes} from 'react-icons/fa'

const navbar = () => {
    const navRef=useRef();
    const showNavbar=()=>{
        navRef.current.classList.toggle("responsive_nav")
    }
    
    return ( 
        <BrowserRouter>
        <nav className="navbar" ref={navRef}>
            <a className="nav-el" onClick={showNavbar}><Lnk className="lnkk" to='#h1' smooth>Home</Lnk></a>
            <a  className="nav-el" onClick={showNavbar}><Lnk className="lnkk" to='#serv' smooth>Services</Lnk></a>
            <a className="nav-el" onClick={showNavbar}><Lnk className="lnkk" to='#about' smooth>About Us</Lnk></a>
            {/* <a href="" className="nav-el">Contact Us</a> */}
            {/* <img src={hamb} className='hamb'/> */}
            {/* <Burger/> */}
            {/* <RightNav/> */}
            <button onClick={showNavbar} className='nav-btn nav-close-btn'>
                <FaTimes/>
            </button>
            <button className="c-but" onClick={showNavbar}><Lnk onClick={showNavbar} className="lnk" to='#contact' smooth>Contact Us</Lnk></button>
        </nav>
        {/* <button className="c-but"><Lnk className="lnk" to='#contact' smooth>Contact Us</Lnk></button> */}

        <button onClick={showNavbar} className='nav-btn'>
            <FaBars/>
        </button>
        </BrowserRouter>
     );
}
 
export default navbar;